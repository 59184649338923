@import url('https://fonts.googleapis.com/css2?family=Nabla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital@1&display=swap');

@keyframes header {
    0%   { transform: scale(0.9); }
    100% { height: 100%; }
}

@font-palette-values --yellow {
    font-family: Nabla;
    base-palette: 7;
}

@font-palette-values --yellow-2 {
    font-family: Nabla;
    base-palette: 5;
}

:root {
    --geo-font: "Nabla", "sans-serif";
    --main-font: "Josefin Sans", "sans-serif";
    --code-font: "Jetbrains mono", "monospace";
    --math-font: "Noto Serif", "serif";

    --number-bg: rgb(100, 50, 50);
    --dark-bg: rgb(20, 7, 4);
    --shadow-bg: rgb(10, 0, 0);
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    font-family: var(--main-font);
    color: white;
}

input {
    font-family: var(--code-font);
}

h1 {
    font-family: var(--geo-font);
    font-palette: --yellow;
    animation: header 2s infinite alternate;
}

h1:hover {
    font-palette: --yellow-2;
}

hr {
    width: 50%;
}

.MJX_LiveRegion {
    background-color: transparent !important;
}

.MJX_HoverRegion{
    background-color: transparent !important;
}

.MJX_ToolTip{
    background-color: transparent !important;
}

.overflow-wrap {
    -webkit-overflow-scrolling: touch;
    margin: 0 -40px;
    overflow: auto;
    overscroll-behavior: contain;
    padding: 0 40px;
}

.overflow-wrap::-webkit-scrollbar {
    width: 12px;
}

.overflow-wrap::-webkit-scrollbar-track {
    background-color: transparent;
}

.overflow-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(111, 105, 105);
    border-radius: 1em;
}

.overflow-wrap::-webkit-scrollbar-thumb:hover {
    background-color: rgb(84, 74, 74);
}

#triangle {
    width: min-content;
}

.nk-ans {
    background-color: var(--dark-bg);
    min-width: 40px;
    color: yellow;
}

.calc-input {
    font-family: var(--math-font);
    width: 5em;
}

.color-picker {
    border: none;
    background-color: transparent;
}

.number {
    aspect-ratio: 1 / 1;
    height: 40px;
    background-color: var(--number-bg);
    transition: 0.15s ease;
    box-shadow: 0 8px 10px var(--shadow-bg);
    font-size: 14px;
}

.number:hover {
    filter: brightness(80%);
    transform: scale(1.12)
}

.body {
    min-height: 100vh;
}

.footer {
    font-family: var(--code-font);
    background-color: var(--dark-bg);
    box-shadow: 0 4px 40px 0 var(--shadow-bg);
}

.footer a {
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}